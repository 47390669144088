var cookieName  = 'pdf_pages';
var cookie;
var pdfUrl      = '/pdfondemand/printpdf?docId=';
var sureReset   = 'Weet u zeker dat u alle pagina\'s wilt verwijderen?';
var currentNode;


$(function(){
  
  currentNode = $('#add-page').attr('rel');

  if ($.cookie(cookieName) == null) cookie = '';
  else cookie = $.cookie(cookieName);
  
  pages_count();
  
  $('.add-page').on('click', function(){
    var nodeId  = $(this).attr('data-nodeid');
    var addText = $(this).attr('data-text-add');
    var remText = $(this).attr('data-text-rem');

    if (cookie.indexOf(nodeId) == -1) {
      cookie += nodeId + '-';
      $(this).text(remText);
    } else {
      cookie = cookie.replace(nodeId+'-','');
      $(this).text(addText);
    }

    $('#selection span').text(cookie.split('-').length-1);
    $.cookie(cookieName,cookie,{expires:7,path:'/'});
    pages_count();
  }).each(function(){
    var nodeId  = $(this).attr('data-nodeid');
    var addText = $(this).attr('data-text-add');
    var remText = $(this).attr('data-text-rem');

    if (cookie.indexOf(nodeId) == -1) $(this).text(addText);
    else $(this).text(remText);
    $('#selection span').text(cookie.split('-').length-1);
  });

  $('#selection').click(function(){
    var url = $(this).attr('data-urlstart');
    url += cookie.substring(0,cookie.length-1);
    location.href = url;
  });

  $('#reset').click(function(){
    if(confirm($(this).attr('data-confirm'))) {
      cookie = '';
      $.cookie(cookieName,cookie,{expires:7,path:'/'});
      $('#selection span').text('0');
      $('#add-page').text($('#add-page').attr('data-text-add'));
      
      pages_count();
    }
  });  
  
  });
  
  function pages_count() {
  var count   = cookie.split('-').length-1;
  var buttons = $('#reset, #selection');
  if (count==0) buttons.addClass('inactive');
  else if (buttons.hasClass('inactive')) buttons.removeClass('inactive');
}
  /*
  if (cookie != '') drawPageList();
  pages_count();
  
  $('#add-page').click(function(){
    if (!$(this).hasClass('inactive')) {
      if (cookie.indexOf(currentNode) == -1) {
        drawPage(currentNode);
        editCookie(currentNode,'add');
        pages_count();
      }
    }
  });
  
  $('#add-results').click(function(){
    $('.theme-results .results-topic a').each(function(){
      var resultNode = $(this).attr('rel');
      if (cookie.indexOf(resultNode) == -1) {
        drawPage(resultNode);
        editCookie(resultNode,'add');
        pages_count();
      }
    });
  });
  
  $('#reset-selection').click(function(){
    if (!$(this).hasClass('inactive')) {
      if (confirm(sureReset)) {
        $('#selected-pages a[class!=remove-page]').each(function(){
          var nodeInfo = $(this).attr('rel');
          removePage(nodeInfo);
        });
        pages_count();
      }
    }
  });
  
  $('#download-selection').click(function(){
    if (!$(this).hasClass('inactive')) {
      var docId = $(this).attr('rel');
      var url   = pdfUrl+docId+'&nodes=';
      $('#selected-pages div').each(function(){
        url += $(this).attr('id').substr(1) + '-';
      });
      url = url.substring(0,url.length-1);
      location.href = url;
    }
  });
  
});

function remove_action(object) {
  var nodeInfo = $(object).next().attr('rel');
  removePage(nodeInfo);
  pages_count();
}

function splitNodeInfo(value) {
  var array = value.split('|');
  return {id: array[0], title: array[1], url: array[2]};
}

function editCookie(nodeInfo,action) {
  if (action=='add') cookie += nodeInfo + ';';
  if (action=='rem') cookie = cookie.replace(nodeInfo + ';','');
  $.cookie(cookieName,cookie,{expires:3,path:'/'});
}

function drawPageList() {
  var nodes = cookie.split(';');
  for(var i=0; i<nodes.length-1; i++) {
    drawPage(nodes[i]);
  }
}

function drawPage(value) {
  var nodeInfo = splitNodeInfo(value);
  var html     = '<div id="n'+nodeInfo.id+'"><a class="remove-page" onclick="remove_action(this)"></a><a href="'+nodeInfo.url+'" rel="'+value+'" class="special">'+nodeInfo.title+'</a></div>';
  $('#selected-pages').append(html);
  if (value==currentNode) $('#add-page').addClass('inactive');
}

function removePage(value) {
  var nodeInfo = splitNodeInfo(value);
  $('#selected-pages #n'+nodeInfo.id).remove();
  editCookie(value,'rem');
  if (value==currentNode) $('#add-page').removeClass('inactive');
  pages_count();
}

function pages_count() {
  var count   = $('#selected-pages div').length;
  var buttons = $('#reset-selection, #download-selection');
  $('#pages-count').text(count);
  if (count==0) buttons.addClass('inactive');
  else if (buttons.hasClass('inactive')) buttons.removeClass('inactive');
}
*/